<template>
    <div class="cart">
        <section class="container max-w-6xl px-5 mx-auto mt-10 mb-5" v-if="settings">
            <p class="flex justify-center mb-10 text-2xl font-medium md:justify-start md:font-light md:text-5xl md:mb-16">Votre panier</p>
            <div class="" v-if="this.$store.state.cart.length <= 0">
                <p class="mb-5">Pas d'articles disponibles.</p>  
            </div>
            <div class="grid gap-4 md:grid-cols-3" v-else>
                <div class="md:col-span-2">
                    <div class="relative px-5 py-3 mx-auto mb-5 shadow-md md:justify-between rounded-xl md:flex md:space-x-10" v-for="product in products" :key="product.id"> 
                        <button @click="removeProduct(product.id)" class="absolute top-0 right-0 p-1 bg-red-500 rounded-md shadow-md">
                            <MinusSmIcon  class="w-3 h-3 mt-1 text-white cursor-pointer md:w-3 md:h-3" aria-hidden="true" />
                        </button>
                        <a :aria-label="product.name" :href="`product/${product.id}/`+sanitizeTitle(`${product.name}`)">
                            <img  v-if="product" v-bind:src="product.photo" class="mx-auto mt-3 md:h-16">
                        </a>
                        <div class="flex flex-col">
                            <a :aria-label="product.name" :href="`product/${product.id}/`+sanitizeTitle(`${product.name}`)">
                                <div class="flex self-center"> 
                                    <p class="flex m-0 mt-4 text-gray-600 md:mt-0 ">{{ product.name }}</p>
                                    <!-- <p class="mt-2 text-xs text-gray-600 ">{{ product.vendor.name }}</p> -->
                                </div>
                            </a>
                            <a :aria-label="product.vendor.name" :href="`vendor/${product.vendor.id}/`+sanitizeTitle(`${product.vendor.name}`)">
                                <div class="self-center "> 
                                    <!-- <p class="flex m-0 mt-5 text-gray-600 md:mt-0 ">{{ product.name }}</p> -->
                                    <p class="mt-1 text-xs text-gray-600 ">{{ product.vendor.name }}</p>
                                </div>
                            </a>
                        </div>
                       
                        
                        
                         <div class="flex flex-row self-center space-x-2">
                            <p class="flex self-center text-xs ">Quantité:</p>
                            <p class="text-xs font-semibold">{{ product.quantity }}</p>
                            <!-- <div class="flex md:items-center">
                                <button
                                class="p-2 m-2 font-extrabold text-green-500 transition duration-500 ease-in-out transform border-transparent cursor-pointer rounded-xl hover:bg-green-50 hover:-translate-y-1 hover:scale-110"
                                @click="decrement(product)">
                                -
                                </button>
                                <div class="w-10 h-10 overflow-hidden bg-gray-100 shadow-md rounded-xl">
                                <input class="w-full h-full font-bold text-center text-1xl" v-model="product.quantity" event.target.value/>
                                </div>
                                <button
                                class="p-2 m-2 font-extrabold text-green-500 transition duration-500 ease-in-out transform rounded cursor-pointer md:p-2 md:m-2 hover:bg-green-50 hover:-translate-y-1 hover:scale-110"
                                @click="increment(product)">
                                +
                                </button>
                            </div> -->
                        </div>
                        <div class="flex flex-col self-center">
                             <div class="flex justify-between space-x-2">
                                 <p class="text-xs">Prix:</p>
                                    <span v-if="product.discount_price > 0">
                                        <p class="text-sm text-gray-600">{{ product.discount_price.toFixed(2) }} {{ currency }}</p>
                                        <!-- <p class="text-sm text-gray-600">{{ currency }}<span class="line-through">{{ product.price.toFixed(2) }}</span> </p> -->
                                    </span>
                                    <span v-else>
                                        <p class="text-sm text-gray-600">{{ product.price.toFixed(2) }} {{ currency }}</p>
                                    </span>
                                 <!-- <p class="text-sm text-gray-600">{{ currency }}{{ product.discount_price.toFixed(2) }}</p> -->
                             </div>
                             <div class="flex justify-between space-x-2">
                                 <p class="text-xs">Total:</p>
                                 <p class="flex self-center text-sm text-gray-600">{{ product ? product.totalPrice.toFixed(2) : '' }} {{ currency }}</p>
                             </div>
                             <div v-if="product.selectedOption">
                                 <p class="text-xs" v-if="product.selectedOption.length == 0">Pas d'extras</p>
                                 <p class="text-xs underline" v-if="product.selectedOption.length != 0">Extras:</p>
                                <div class="flex flex-col">
                                    <div class="flex justify-between space-x-2">
                                        <p class="text-xs" v-for="option in product.selectedOption" :key="option.id">{{ option.name }}</p>
                                    
                                    </div>
                                    <div class="flex justify-between space-x-2">
                                        <p class="text-sm text-gray-600" v-for="option in product.selectedOption" :key="option.id">{{ currency }} {{ option.price }}</p>
                                    </div>
                                    
                                </div>
                             </div>
                             
                        </div>
                       
                    </div>
                </div>
                
                <div class="md:col-span-1" v-show="products">
                    <p class="py-3 text-sm font-semibold text-gray-600 md:mt-0">Résumé de la commande</p>
                    <div class="font-bold divide-y divide-black">
                        <p class="text-xs font-light text-gray-600 md:mb-3 md:mt-0">Ceci n'inclut pas les frais de livraison</p>
                        <div></div>
                    </div>
                    <div class="flex justify-between mt-4">
                        <p class="text-sm font-light text-gray-600">Nb Articles</p>
                        <p class="font-bold text-black">{{ cartItemCount }}</p>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-sm font-light text-gray-600">Sous-total</p>
                        <span v-if="cartSubTotal">
                        <p class="font-bold text-black"> {{ cartSubTotal.toFixed(2) }} {{ currency }}</p>
                        </span>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-sm font-light text-gray-600">Remise</p>
                        <p class="font-bold text-black" v-if="discount">

                            <span v-if="discount.percentage == 1">%</span>
                            <span v-else></span>
                            {{ discount.discount }}{{ currency }}
                        </p>
                    </div>
                    <div class="py-4 font-bold divide-y-2 divide-black divide-dashed">
                        <div></div>
                        <div></div>
                    </div>
                    <div class="flex justify-between mt-1">
                        <p class="text-sm font-semibold text-gray-600">Total</p>
                        <span v-if="totalAmt">
                            <p class="text-sm font-bold text-black md:text-base"> {{ totalAmt.toFixed(2) }} {{ currency }}</p>
                        </span>
                    </div>
                    <div class="px-5 mt-10 border-2 border-gray-100 rounded py-7" v-show="!isLoggedIn">
                        <div class="flex justify-between">
                            <div>
                                <p class="font-bold">Avez vous un compte?</p>
                                <span class="text-sm font-light">
                                
                                    <router-link to="/register" class="text-sm font-light underline"> Rejoindre</router-link>
                                    ou
                                    <router-link to="/login" class="underline"> Connectez vous</router-link>
                                    pour un shopping plus cool
                                </span>
                            </div>
                            <span class="text-2xl font-bold"><UserIcon class="w-6 h-6 text-black" aria-hidden="true" /></span>
                        </div>
                    </div>
                    <div v-show="isLoggedIn">
                        <a :href="`checkout`" v-if="settings" class="flex justify-between w-full px-5 py-10 mt-10 rounded" :style="{ 'background-color': settings.colors.primaryColor }">
                            <p class="self-center font-semibold text-primary">Continuer l'achat</p>
                            <span class="self-center w-12 h-12 p-3 text-2xl font-light text-center text-black bg-white rounded-full">
                                <ArrowCircleRightIcon class="w-6 h-6 text-black" aria-hidden="true" />
                            </span>
                        </a>
                    </div>
                    <div class="mt-8 font-semibold text-black ">
                        <div class="pb-10 underline ">Utiliser un coupon</div>
                            <input type="text" name="text" 
                                placeholder="Code du coupon" 
                                v-model="coupon" 
                                class="block w-full px-2 py-2 mt-1 text-sm border-b-2 border-black dark:border-gray-600 dark:bg-gray-700 focus:border-green-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input">
                            <div class="text-center">
                                <button @click="getCoupon" class="px-6 py-3 mt-5 font-semibold border-2 border-gray-300 rounded-full" type="submit">Utiliser</button>
                            </div>
                        </div>
                        <div class="flex mt-8 text-sm font-semibold text-gray-600">
                            <ShoppingBagIcon class="w-4 h-4 mr-2 text-black" aria-hidden="true" />
                            <p> Shopping sécurisé et crypté</p>
                        </div>
                </div>
            </div>
            
           
        </section>
    </div>
    <Download/>
</template>

<script>
import axios from 'axios'
import Download from '@/components/Downloads.vue'
import { UserIcon, ArrowCircleRightIcon, MinusSmIcon, ShoppingBagIcon } from '@heroicons/vue/outline'
import { notify } from "@kyvg/vue3-notification"

export default {
  name: 'cart',
  components: {
    Download,
    UserIcon,
    ArrowCircleRightIcon,
    MinusSmIcon,
    ShoppingBagIcon
    // MenuTab
  },
  data () {
    return {
        isLoggedIn: false,
        products: null,
        product: null,
        currency: null,
        quantity: 1,
        coupon: null,
        discount: null,
        animationDown :{
            translateY:'translateY(-100%)',
            // eslint-disable-next-line no-dupe-keys
            translateY:'translateY(100%)'
        },
        AuthStr: 'Bearer ' + localStorage.getItem('authToken'),
        orders: null,
        settings: null,
        base_url: this.$store.state.baseUrl
    }
  },
  computed: {

    increment(product) {
         //  let item = this.$store.state.cart.find(p => p.id == product.id);
         // console.log(item.quantity)
        this.$store.commit('increment', product)
    },
     
    decrement(product) {
        this.$store.commit('decrement', product);
    },

    cartSubTotal() {
        let total = 0;
        // let totalAmt = 0;
        for (let product of this.$store.state.cart) {
            total += Number(product.totalPrice);
            if(product.selectedOption[0]){
                product.selectedOption.forEach((item, i) => {
                    total += Number(product.selectedOption[i].price);
                })
            }else if(this.discount){
                if(this.discount.percentage == 1){
                    total *= this.discount.discount/ 100;
                }else if(this.discount.percentage == 0) {
                    total -= this.discount.discount;
                }
            }
            
        }
        return total;
    },

    cartItemCount() {
      return this.$store.getters.count;
    },

    totalAmt(){
        //sum totalprices of products in the cart and divide or subtract discount from it
        //determine whether discount is % or a fixed amount to enable you to calculate the totalAmt
       let total = 0;
        total = Number(this.cartSubTotal);
        return total;
    }
},
  mounted () {
    if (localStorage.getItem("authToken")) {
      this.isLoggedIn = true;
      this.user = JSON.parse(localStorage.getItem("user"))
    }

    if (localStorage.getItem("cart")) {
      this.products = JSON.parse(localStorage.getItem("cart"))
    //   this.product = JSON.parse(localStorage.getItem("cart"))
    }

    axios.get(this.base_url+'api/app/settings')
    .then(response => (this.settings = response.data))
    .catch(error => console.log(error))
        
    
  },

    created() {
        this.getCurrency();
        this.getCoupon();
    },

   methods: {

       removeProduct(product)
       {
            const cartItems = JSON.parse(localStorage.getItem("cart"));
            const index = cartItems.findIndex(({ id }) => id === product);
            cartItems.splice(index, 1);
            localStorage.setItem("cart", JSON.stringify(cartItems));
            this.cart = JSON.parse(localStorage.getItem("cart"));
            notify({
                type: "success",
                title: "Cart",
                text:  'Product Deleted successfully',
            })
            window.location.href = '/cart'
       },

       getCoupon(){
           if(this.coupon){
           this.$store.commit('loading', true)
           axios.get(this.base_url+'api/coupons/' + this.coupon)
            .then((response) => {
                this.$store.commit('loading', false)
                this.discount = response.data
            })
            // for(let product of this.$store.state.cart) {
            //     console.log(product.id)
            //     if(product == this.discount.products){
                    return this.discount
            //     }
            // }
           }
       },

        getCurrency() {
            this.$store.commit('loading', true)
            let setting;
            axios.get(this.base_url+'api/app/settings')
            .then( (response) => {
                this.$store.commit('loading', false)
                setting = response.data;
                this.currency = setting.strings.currency
            })
            return this.currency;
        }, 

        sanitizeTitle(title) {
            var slug = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            slug = slug.replace(/đ/gi, 'd');
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, '');
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, '-');
            
            return slug;
        },


        // increment(product) {
        //     if(product.available_qty > 1){
        //         this.quantity++;
        //         this.totalPrice = product.price + this.quantity;
        //     }
        // },

        // decrement() {
        //     if(this.quantity === 1)
        //     {
        //          notify({
        //             type: "error",
        //             title: "Cart",
        //             text: "You cannot add 0 product",
        //         });
        //     }else{
        //         this.quantity--
        //     }
        // },

    }
}
</script>
